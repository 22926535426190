import React from "react";
// import { BurgerContext } from "../Components/burgerContext";
import Bun from "./Bun";
import Base from "./Base";
import Tomato from "./Tomato";
import Lettuse from "./Lettuse";
import Cheese from "./Cheese";
import Meat from "./Meat";

function Burger(props) {
  const { ingredients } = props;

  return (
    <div>
      <Bun />

      {ingredients.Lettuse
        ? Array(ingredients.Lettuse)
            .fill(true)
            .map((_, i) => <Lettuse key={i} />)
        : ""}
      {ingredients.Cheese
        ? Array(ingredients.Cheese)
            .fill(true)
            .map((_, i) => <Cheese key={i} />)
        : ""}
      {ingredients.Meat
        ? Array(ingredients.Meat)
            .fill(true)
            .map((_, i) => <Meat key={i} />)
        : ""}
      {ingredients.Tomato
        ? Array(ingredients.Tomato)
            .fill(true)
            .map((_, i) => <Tomato key={i} />)
        : ""}

      <Base />
    </div>
  );
}

export default Burger;

import React from 'react'

function Base() {
    return (
        <div className="bottom-bun">
          <img src="images/bottom-bun.png" alt="" />
        </div>
      );
}

export default Base
import React, { useContext } from "react";
import { BurgerContext } from "../Components/burgerContext";

function Recepie() {
  const { price, ingredients } = useContext(BurgerContext);

  return (
    <div className="recepie">
      <div className="recepie_table">
        <b>Current Price: RS {price}</b>
        <div className="d-flex">
          <p>Tomato</p>
          <button className="btn" onClick={ingredients.addTomato}>
            Add
          </button>
          <button className="btn" onClick={ingredients.removeTomato}>
            Remove
          </button>
          <span>{ingredients.tomato}</span>
        </div>

        <div className="d-flex">
          <p>Lettuse</p>
          <button className="btn" onClick={ingredients.addLettuse}>
            Add
          </button>
          <button className="btn" onClick={ingredients.removeLettuse}>
            Remove
          </button>
          <span>{ingredients.lettuse}</span>
        </div>

        <div className="d-flex">
          <p>Cheese</p>
          <button className="btn" onClick={ingredients.addCheese}>
            Add
          </button>
          <button className="btn" onClick={ingredients.removeCheese}>
            Remove
          </button>
          <span>{ingredients.cheese}</span>
        </div>
        <div className="d-flex">
          <p>Meat</p>
          <button className="btn" onClick={ingredients.addMeat}>
            Add
          </button>
          <button className="btn" onClick={ingredients.removeMeat}>
            Remove
          </button>
          <span>{ingredients.meat}</span>
        </div>
      </div>
    </div>
  );
}

export default Recepie;

import "./App.css";
import Burger from "./Components/Burger";
import Recepie from "./Components/Recepie";
import { BurgerProvider } from "./Components/burgerContext";
import { useState } from "react";

function App() {
  const [burger_price, setBurgerPrice] = useState(100);
  const [tomato, setTomato] = useState(0);
  const [lettuse, setLettuse] = useState(0);
  const [cheese, setCheese] = useState(0);
  const [meat, setMeat] = useState(0);

  const burgerPrice = (price, action) => {
    switch (action) {
      case "ADD":
        setBurgerPrice((prevPrice) => prevPrice + price);
        break;
      case "REMOVE":
        setBurgerPrice((prevPrice) => prevPrice - price);
        break;
      default:
        setBurgerPrice(price);
    }
  };

  const addTomato = () => {
    burgerPrice(10, "ADD");
    setTomato(tomato + 1);
  };
  const removeTomato = () => {
    if (tomato > 0) {
      burgerPrice(10, "REMOVE");
      setTomato(tomato - 1);
    }
  };

  const addLettuse = () => {
    burgerPrice(20, "ADD");
    setLettuse(lettuse + 1);
  };
  const removeLettuse = () => {
    if (lettuse > 0) {
      burgerPrice(20, "REMOVE");
      setLettuse(lettuse - 1);
    }
  };

  const addCheese = () => {
    burgerPrice(30, "ADD");
    setCheese(cheese + 1);
  };

  const removeCheese = () => {
    if (cheese > 0) {
      burgerPrice(30, "REMOVE");
      setCheese(cheese - 1);
    }
  };

  const addMeat = () => {
    burgerPrice(40, "ADD");
    setMeat(meat + 1);
  };

  const removeMeat = () => {
    if (meat > 0) {
      burgerPrice(40, "REMOVE");
      setMeat(meat - 1);
    }
  };

  const recepie = {
    tomato,
    addTomato,
    removeTomato,
    lettuse,
    addLettuse,
    removeLettuse,
    cheese,
    addCheese,
    removeCheese,
    meat,
    addMeat,
    removeMeat,
  };

  const ingredient = {
    Tomato: tomato,
    Lettuse: lettuse,
    Cheese: cheese,
    Meat: meat,
  }

  return (
    <div className="App">
      <BurgerProvider
        value={{
          price: burger_price,
          ingredients: recepie,
        }}
      >
        <Burger ingredients={ingredient}/>
        <Recepie />
      </BurgerProvider>
    </div>
  );
}

export default App;

import React from 'react'
import '../App.css'

function Tomato() {
  return (
    <div className='tomato d-flex'>
        <div></div>
        <div></div>
        <div></div>
    </div>
  )
}

export default Tomato
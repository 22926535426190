import React from "react";
import "../App.css";

function Bun() {
  return (
    <div className="upper-bun">
      <img src="images/upper-bun.png" alt="" />
    </div>
  );
}

export default Bun;
